import { useEffect, useState } from "react";
import "./App.css";
import "animate.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

function App() {
  const [loggedInClicked, setLoggedInClicked] = useState(false);
  const [name, setName] = useState("");
  const [currentStep, setCurrentStep] = useState("password");
  const [inputtedPassword, setInputtedPassword] = useState("");
  const [githubLink, setGithubLink] = useState();

  const serverPath = window.location.href.includes("localhost")
    ? "http://localhost:3001"
    : "https://githubeducationserver.maxmatthe.ws";

  useEffect(() => {
    if (currentStep === "loggedIn") {
      (async () => {
        const res = await fetch(
          `${serverPath}/generateLink/${inputtedPassword}?studentID=${name}`
        );
        const link = await res.text();
        setGithubLink(link);
        console.log(link);
      })();
    }
  }, [currentStep]);

  if (currentStep === "password") {
    const formSubmit = async (evt) => {
      evt.preventDefault();
      const res = await fetch(`${serverPath}/auth/${inputtedPassword}`);
      const body = await res.json();
      if (body.auth) {
        setCurrentStep("stepOne");
      } else {
        window.alert("Incorrect password, please try again");
      }
    };

    return (
      <form style={{ width: "100%" }} onSubmit={formSubmit}>
        <TextField
          id="standard-basic"
          label="Your First and Last Name?"
          variant="standard"
          style={{ width: "100vw", maxWidth: 400, minWidth: 300 }}
          value={name}
          onChange={(evt) => {
            setName(evt.target.value);
          }}
        />
        <br />
        <TextField
          id="standard-basic"
          label="Password?"
          variant="standard"
          style={{ width: "100vw", maxWidth: 400, minWidth: 300 }}
          value={inputtedPassword}
          onChange={(evt) => {
            setInputtedPassword(evt.target.value);
          }}
          type="password"
        />
        <br />
        <Button
          type="submit"
          variant="contained"
          size="large"
          style={{ marginTop: 30 }}
        >
          Authorize
        </Button>
        <p>
          If you have already activated you student pack, you can access it{" "}
          <a href="https://education.github.com/pack/offers#offers">here</a>.
        </p>
        <p>
          Do <span className="bold">not</span> complete this process twice.
        </p>
      </form>
    );
  }

  if (currentStep === "stepOne") {
    return (
      <div>
        <p className="animate__animated animate__zoomInDown">
          Read <span className="bold">all</span> the instructions on the screen
          before clicking anything.
        </p>
        <p className="animate__animated animate__bounceInLeft animate__delay-1s">
          Click{" "}
          <a
            href="https://github.com/login"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setLoggedInClicked(true);
            }}
          >
            here
          </a>{" "}
          to open GitHub, then come back to this page.
        </p>
        {loggedInClicked && (
          <div>
            <p>Did it take you to a login screen or your homepage?</p>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setCurrentStep("login");
              }}
            >
              Login
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setCurrentStep("loggedIn");
              }}
            >
              Homepage
            </Button>
          </div>
        )}
      </div>
    );
  }

  if (currentStep === "login") {
    return (
      <div>
        <p>
          Please go back to{" "}
          <a href="https://github.com/login" target="_blank" rel="noreferrer">
            GitHub
          </a>
          , sign in, then return to this screen
        </p>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            setCurrentStep("loggedIn");
          }}
        >
          I'm Logged In
        </Button>
      </div>
    );
  }

  if (currentStep === "loggedIn") {
    if (githubLink) {
      return (
        <div>
          <p>Your student pack is ready for activation...</p>
          <p>...but before you go, one last instruction:</p>
          <p>
            1) Activate your student pack <a href={githubLink}>here</a>.
          </p>
          <p>
            2) After seeing the "We're so glad you're here" message{" "}
            <span className="bold">CLOSE THE TAB!</span>
          </p>
          <p>
            3) You can access the offers{" "}
            <a
              href="https://education.github.com/pack/offers#offers"
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </div>
      );
    }

    return (
      <div>
        <p>Please wait, redirecting you to the GitHub Activation</p>
      </div>
    );
  }
}

export default App;
